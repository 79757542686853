
import { defineComponent } from "@vue/runtime-dom";
import ImageItem from "@/components/ImageItem.vue";

export default defineComponent({
  name: "Project Card",
  components: {
    ImageItem,
  },
  props: {
    project: {
      id: String,
      title: String,
      intro: String,
      description: String,
      featuredImage: String,
      required: true,
    } as any,
  },
  computed: {
    slicedDescription(): string | void {
      return this.project.description?.slice(0, 185) + "...";
    },
  },
  methods: {
    getImgUrl(fileName: string) {
      if (!fileName) return;
      return require(`../../assets/images/project-images/${this.project.id}/` +
        fileName);
    },
  },
});
