
import { kokoStore } from "@/main";
import ImageItem from "@/components/ImageItem.vue";
import { analytics } from "@/init-firebase";
import { logEvent } from "firebase/analytics";
import { defineComponent } from "@vue/runtime-dom";

export interface IProject {
  id: string;
  title: string;
  description: string;
  featuredImage: string;
  images: IProjectImage[];
  figmaUrl?: string;
}

export interface IProjectImage {
  name: string;
  fileName: string;
  pics: string[];
  break: string;
  bgColor: string;
  style: string;
  class: string;
}

export default defineComponent({
  name: "Project",
  components: {
    ImageItem,
  },
  data() {
    return {
      project: {} as IProject,
      images: [] as IProjectImage[],
      rootPath: "",
    };
  },
  mounted() {
    this.getProject();
    if (Object.keys(this.project).length === 0) {
      this.$router.push({ path: "../home" });
    }
  },
  methods: {
    getProject(): void {
      this.images.length = 0;
      const projectId = this.$route.params.id as string;
      this.project = { ...kokoStore.getProjectById(projectId) };
      setTimeout(() => this.constructImagePaths());
      window.scrollTo(0, 0);

      logEvent(analytics, "project_visits", { title: this.project.title });
    },
    constructImagePaths(): void {
      if (!this.project?.images) {
        console.error("images don't exist");
        return;
      }

      this.project.images.forEach((image): void => {
        this.images.push({
          name: image.name,
          fileName: image.name,
          pics: image.pics,
          break: image.break,
          bgColor: image.bgColor,
          style: image.style,
          class: image.class,
        });
      });
    },
    getImgUrl(fileName: string) {
      return require(`../assets/images/project-images/${this.project.id}/${fileName}`);
    },
  },
  watch: {
    $route() {
      this.getProject();
    },
  },
});
