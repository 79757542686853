
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nav",
  props: {
    navOptions: Array,
  },
  data() {
    return {
      openBurgerMenu: false,
    };
  },
});
