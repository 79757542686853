import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { ref: "top" }
const _hoisted_2 = {
  key: 0,
  ref: "footer"
}
const _hoisted_3 = {
  key: 1,
  ref: "projectFooter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")
  const _component_router_view = _resolveComponent("router-view")
  const _component_UpArrow = _resolveComponent("UpArrow")
  const _component_Footer = _resolveComponent("Footer")
  const _component_ProjectFooter = _resolveComponent("ProjectFooter")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, null, 512),
    _createVNode(_component_Nav, { navOptions: _ctx.navOptions }, null, 8, ["navOptions"]),
    _createVNode(_component_router_view, { class: "router-view" }),
    _createVNode("div", {
      class: ["up-arrow", { display: _ctx.upArrow }],
      ref: "upArrow",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollTo('top')))
    }, [
      _createVNode(_component_UpArrow)
    ], 2),
    (!_ctx.isProject)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_Footer)
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.isProject)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_ProjectFooter)
        ], 512))
      : _createCommentVNode("", true)
  ], 64))
}