
import { defineComponent } from "@vue/runtime-dom";
import ImageSpinner from "./ImageSpinner.vue";

export default defineComponent({
  name: "ImageItem",
  components: {
    ImageSpinner,
  },
  props: {
    source: { type: String, required: true },
    alt: String,
    imageStyle: String,
    imageClass: String,
  },
});
