
import { defineComponent } from '@vue/runtime-dom';

export default defineComponent({
  name: "Social Badge",
  props: {
    url: { type: String, required: true },
    faIcon: { type: String, required: true },
  },
});
