
import { kokoStore } from "@/main";
import { defineComponent } from "@vue/runtime-dom";

export default defineComponent({
  name: "ProjectFooter",
  data() {
    return { prevProject: {id: null}, nextProject: {id: null} };
  },
  mounted() {
    this.getPrevAndNextProjects();
  },
  methods: {
    getPrevAndNextProjects() {
      const projectId = this.$route.params.id as string;
      this.prevProject = kokoStore.getPrevProject(projectId);
      this.nextProject = kokoStore.getNextProject(projectId);
    },
  },
  watch: {
    $route() {
      this.getPrevAndNextProjects();
    },
  },
});
