
import { defineComponent } from "@vue/runtime-dom";
import { analytics } from "@/init-firebase";
import { logEvent } from "firebase/analytics";

import ImageItem from "@/components/ImageItem.vue";

export interface IArtworks {
  pathLong: string;
  pathShort: string;
  name: string;
}

export default defineComponent({
  name: "Art",
  components: {
    ImageItem,
  },
  data() {
    return {
      artworks: [] as IArtworks[],
    };
  },
  mounted() {
    logEvent(analytics, "project_visits", { title: "Art" });

    this.importAll(
      require.context("../assets/images/art-images", true, /webp$/)
    );
  },
  methods: {
    /**
     * Get's files from a given folder path and puts it in a javascript array
     * @param r  require.context("../relative-path", true, /webp$/)
     * @returns
     */
    importAll(r: any): void {
      r.keys().forEach((key: string) => {
        this.artworks.push({
          pathLong: r(key),
          pathShort: key,
          name: this.getName(key),
        });
      });
    },
    getName(path: string): string {
      return path.split(new RegExp(/(?<=\.\/)(.*?)(?=.webp)/g))[1];
    },
  },
});
