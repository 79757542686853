
import { defineComponent } from "@vue/runtime-dom";
import Nav from "@/components/Nav.vue";
import UpArrow from "@/components/UpArrow.vue";
import Footer from "@/components/Footer.vue";
import ProjectFooter from "@/components/Projects/ProjectFooter.vue";
import { scrollMeTo } from "./main";

export default defineComponent({
  name: "App",
  components: {
    Nav,
    UpArrow,
    Footer,
    ProjectFooter,
  },
  data() {
    return {
      navOptions: ["HOME", "ART", "ABOUT"],
      upArrow: false,
      isProject: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    console.log(
      "%cMade with 🧡 by Abel",
      "background: #222; color: #bada55; font-size: 24px"
    );
    console.log("%chttps://abelhii.com", "font-size: 16px");
  },
  watch: {
    $route() {
      this.isProject = this.$route.path.includes("projects");
    },
  },
  methods: {
    scrollTo(refName: string) {
      const element = this.$refs[refName] as HTMLElement;
      scrollMeTo(element);
    },
    handleScroll() {
      if (window.scrollY >= 1250) this.upArrow = true;
      else this.upArrow = false;

      const footer: HTMLElement = (!this.isProject
        ? this.$refs.footer
        : this.$refs.projectFooter) as HTMLElement;
      const upArrowElem = this.$refs.upArrow as HTMLElement;
      if (!footer || !upArrowElem) return;
      if (this.isInView(footer)) {
        const rect = footer.getBoundingClientRect();
        upArrowElem.style.bottom = `${window.innerHeight - rect.top - 20}px`;
      } else {
        upArrowElem.style.bottom = "2rem";
      }
    },
    isInView(el: HTMLElement) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top + 32 <=
        (window.innerHeight || document.documentElement.clientHeight)
      );
    },
  },
});
