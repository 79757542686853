
import { defineComponent } from "vue";
import Scroll from "@/components/Scroll.vue";
import DownArrow from "@/components/DownArrow.vue";
import ProjectCard from "@/components/Projects/ProjectCard.vue";
import { kokoStore, scrollMeTo } from "@/main";

export default defineComponent({
  name: "Home",
  components: {
    Scroll,
    DownArrow,
    ProjectCard,
  },
  data() {
    return {
      scrollWord: "DESIGN USER INTERFACE USER EXPERIENCE BRANDING",
      projects: kokoStore.store.projects,
    };
  },
  methods: {
    scrollTo(refName: string) {
      const element = this.$refs[refName] as HTMLElement;
      scrollMeTo(element);
    },
  },
});
