<template>
  <div class="avatar">
    <div class="bg-triangle"></div>
    <svg
      version="1.1"
      id="minikoko"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1167.1 948.5"
      style="enable-background:new 0 0 1167.1 948.5;"
      xml:space="preserve"
    >
      <g id="head">
        <g id="base-hair">
          <path
            id="Hair_base"
            class="st0"
            d="M1049.7,467.3c6.7-13.6,3.2-30.2-8.5-39.8c-4.9-4-11.6-8-20.5-11.1c0,0-18.2-5.6-11.5-19.4
			c0,0,6.8-14.1,21.2-24.9c3.6-2.7,6.5-6.4,8.5-10.5c0.1-0.2,0.2-0.4,0.3-0.5c8-17.2-2.1-37.5-20.5-42.1c-8.3-2.1-19.4-3.4-34-2.8
			c0,0-51.9,11.9-42.5-24.5c0,0,2.8-11.7,15.4-18.9c6.1-3.5,11.3-8.9,12.9-15.7c1.4-5.9,1-13.5-4.2-22.6c-5.3-9.3-14.1-16-24-20
			c-8.4-3.4-20.3-7-34.8-8c0,0-11.2-0.9-11-10.7c0.1-3.6,1.9-7,4.5-9.5c0.4-0.3,0.7-0.7,1.1-1.1c18.5-18.9,9.3-50.5-16-58.2
			c-12-3.6-29.1-4.9-51.5,2.3c0,0-27.9,8.5-45.1,2.6c-8.8-3-13.2-12.9-9.9-21.6l0.2-0.4c1.6-4.2,1.6-8.9-0.3-13
			c-5.1-10.5-20.9-25-70.4-8.7c-7.5,2.5-14.8,5.7-21.9,9.2c-12.3,6-41,17.7-48.3-2c0,0-4.9-27.9-46.4-27.1
			c-27.5,0.6-53.9,12.5-72.6,32.6c-12.7,13.7-25.1,33.4-29.9,60.3c0,0-80.7-114-174.3-71c0,0-34.4,17.2-15.5,55.5
			c-2-1.6-43.1-34.2-81.5-14.5c-17.7,9.1-26.5,29.6-18.7,47.9c0.1,0.2,0.2,0.5,0.3,0.7c6.6,14.8-1.5,32.5-17.5,35
			c-7.6,1.3-17.6,1-30.9-1.6c3.3,1.4,54.3,22,65.2-20.2c0,0,0.7,15.3,14.5,29.2c0,0-3.1,14.9-31,19.3c-6.5,1-12.8,2.8-18.6,6
			c-2.2,1.2-4.6,2.6-7,4.3c-23.7,16.4-28.2,49.1-9.6,71.2c3.2,3.8,7.2,7.6,12.2,11.2c2.6,1.9,4.9,4.2,6.3,7.1
			c3.5,7,6.2,20.1-14.8,28.6c0,0-6.7,2.2-14.5,7.1c-20.7,12.9-27.4,40-13.2,59.8c2.1,3,4.7,5.7,7.9,7.9c5.1,3.6,8.7,9.1,8.8,15.4
			c0.1,4.8-1.6,10.2-7.6,15c0,0-22.2,13.3-28.4,36.8c-3.7,14.1,5.2,28.8,19.5,31.6c4.1,0.8,8.9,1.1,14.5,0.8c0,0,11.8,0.2,14.1,12
			c0.6,3.2,0.2,6.6-0.9,9.7c-2,5.3-6.8,13.8-18.9,21.7c-10.2,6.7-16.4,18.3-14.6,30.4c0.1,0.9,0.3,1.8,0.5,2.7
			c1.7,8.2,6.8,15.2,14.3,18.8c8,3.9,20.8,7.2,38.7,3.6c9.9-2,18.9,7,16.4,16.7c-0.8,3.2-2.5,7-5.4,11.4c0,0-30.9,36.6,7.8,56.5
			c0,0,17.8,10.1,47.4,1.5c0,0,18.1-5.9,24.6,7.1c2.6,5.1,2,11.3-0.9,16.2c-6,10.2-16,33.9,8.9,48.6c3.1,1.8,6.5,3.2,9.9,4.2
			c6.8,2.1,18.2,4.6,32.3,3.8c3.9-0.2,7.7-0.8,11.5-1.5c11.2-2,55.1-7.3,97,22.4c0,0,10.9,38.1,69.2,42.3c0,0,29.8-0.1,52.7-14.1
			c0,0,30.9-15.1,62.9-10.9c0,0,14.6,25,78.6,25c0,0,26.4-2,47.1-17.1c0,0,14.6-10.8,45.8-7.9c0,0,32.6,7.9,39-27.6
			c0,0-1.4-21.8,28.2-24c0,0,42.2,6.3,48.7-22.7c0,0,0.8-6.9-1.5-15.1c-4.4-15.5,4.7-32,20.1-36.8c0.3-0.1,0.5-0.2,0.8-0.3
			c0,0,9.9-0.7,21.1-6.6c28.4-15.1,35.8-53.5,14.4-77.5c-0.8-0.9-1.6-1.7-2.4-2.6c0,0-13.5-14.5-3-27.5c2-2.4,4.6-4.2,7.5-5.5
			c5.4-2.4,15.9-6.2,30.2-7.1c9.9-0.6,19.5-3.1,27.1-10.1c8.7-8,13.1-20.5,11.2-32.2c-1.9-11.8-10.4-21.1-20.5-26.9
			c0,0-19.4-11.3-5.6-25C1010.3,500.2,1038.2,490.9,1049.7,467.3z M216.9,189.7c-0.3-2.4-0.9-5.9-2.6-9.4c-3.4-6.9-4-15-0.4-21.8
			c6.6-12.2,23.9-24.6,69-8C276.5,148.7,215,132.8,216.9,189.7z M279.1,752.6c0,0-43.1,69.2,40.1,87.5c0,0-55.2-19.4-42.3-65.1
			L279.1,752.6z M163.8,372c0,0-61.1,21.9-76.3,63.2c-3,8-3.6,16.9-0.8,24.9c4.4,12.7,17.9,28.5,58.4,27l5.4-12.1
			c0,0-18.2-0.1-32.5-7.7c-13.4-7.2-18.3-24.1-11.9-37.9c6.1-13.1,19.8-32.2,50.4-45.5L163.8,372z"
          />
          <path
            class="st0"
            d="M1034.1,423.7c0,0,88.5,38.4,2.6,137.5l-9-12.2c0,0,76.4-80.7,4.3-119.6L1034.1,423.7z"
          />
          <path
            class="st0"
            d="M156.1,545.3c0,0,15.3,22.6-11.3,32.3c0,0-29.3,9-33.5,35.9c-1.7,10.8,1.5,21.9,8.3,30.6
			c4.7,6,12.1,12.8,23.3,17.8c0,0,24.2,7.5,0.3,42c0,0,23.5-18.1,13-39.1c-2.5-4.9-6.7-8.7-11.5-11.5c-6.7-3.8-18.4-12.3-21.4-26.5
			c-2-9.1,0.4-18.6,5.9-26.1c3.6-4.9,9.2-10.3,17.8-14.3c0,0,27.5-12.1,24.2-38.5l-8.6-7.2L156.1,545.3z"
          />
          <path
            class="st0"
            d="M968.3,641c0,0,32.6,17,34,54.2c0.7,20.3-8.9,40-26.4,50.3c-3.6,2.1-7.8,4.1-12.5,5.6
			c-7.2,2.3-13.8,6.3-18.4,12.3c-6.2,8.1-10.7,20.3,0.7,33.5c0,0-26-19.1-3.7-42.6c3.9-4.1,8.7-7.1,13.9-9.4
			c10.2-4.6,31.3-16.7,35.4-40.2c0,0,6-27.9-23.7-47.2L968.3,641z"
          />
          <path
            class="st0"
            d="M931.9,212c0,0,66.3,14.4,39.2,63.7c0,0-12.3,27.5,28.5,24c0,0-34.8,13.1-38-8.7c-0.7-4.8,0.5-9.6,2.7-13.8
			C970.6,265.1,984.4,228.6,931.9,212z"
          />
          <path
            class="st1"
            d="M336.5,583.2c0,0-25.7,22.9,4.5,41.1c7,4.2,13,10,16.7,17.2c5,9.7,7.4,23.8-3,41.2
			c-6.9,11.5-11.2,24.4-11.6,37.7c-0.6,20.3,6.8,44.1,44.2,46.9l6.9-6.1c0,0-37.7,1.7-40.8-36.3c-1.1-13.6,2.9-27.1,10.3-38.6
			c4.9-7.5,10.1-19.5,7.4-34c-2.9-15.7-14.1-28.5-28.6-35.1C334.3,613.6,325.5,604.6,336.5,583.2z"
          />
          <path
            class="st2"
            d="M367,620.7c0,0,30.2,32.1,5.5,67c-5,7.1-9,14.9-11,23.3c-4.1,17.4-2.2,41.8,37.3,47.7c0,0-44.2-7.2-29.9-51.4
			c2-6.1,5.2-11.8,9.1-16.9C386.3,679.4,401.4,651.1,367,620.7z"
          />
          <path
            class="st1"
            d="M392.6,586c0,0-16.6,22.5,11.8,35.8c0,0,36.6,8.4,26.6,43.9c-1.4,5-4,9.6-7.2,13.8
			c-9.2,12.1-29.2,44.8,8.9,59.2c0,0,34.2,11.8,9.3,50.3c0,0,32.9-26,5-51.9c-3.4-3.2-7.5-5.6-11.7-7.7c-7.9-3.9-23.2-15.4-4.6-40.5
			c4.2-5.7,7.8-11.9,9.9-18.6c5.1-16.2,7.1-42.7-28-53.5c-3.7-1.1-7.3-3-10-5.8c-7-7.2-12.6-23.2,20.3-52.7
			C423,558.4,406.6,567,392.6,586z"
          />
          <path
            class="st1"
            d="M305,229.9c3.6,14.2-4.2,28.9-18,33.7c-0.9,0.3-1.3,0.4-1.3,0.4c-40.3,14.2-25,45.2-16.3,58.1
			c3.8,5.7,5.8,12.7,4.4,19.5c-3,14.4-15.5,18.3-15.5,18.3c-42.4,21.4-22.8,55.7-17.3,63.8c-4.6,0.2-7.5,0.9-7.5,0.9
			c-7.4-7.4-9-19.4-9.2-26.8c-0.1-3.3,0.1-6.7,0.7-10c4.4-22.1,22-31,30.8-34c6-2.1,10.8-7.3,11.7-13.6c1.2-8.7-4.9-15.1-4.9-15.1
			c-6.8-9.1-8.7-20.3-9.2-27c-0.2-2.9-0.3-5.8,0.1-8.6c2.7-19.5,20.3-27.3,28.6-29.9c5.2-1.6,10-4.5,13.5-8.7
			c8.2-10,4.9-20.6,4.9-20.6c-9.5-28.4,6.6-46,6.6-46l0,0C299.2,199.7,302.2,219.1,305,229.9z"
          />
          <path
            class="st2"
            d="M166.7,270.3c0,0-22.1,38.7,12.2,59.3c4.7,2.8,8.5,6.9,10.8,11.8c3.2,6.8,4.3,16.4-5.7,26.2
			c-4,3.9-8.9,6.5-14.1,8.3c-11.4,3.9-36.8,16.1-30.4,47.7c0,0,1.4,11.5,12.2,18.7c4.2,2.8,7.6,6.9,9.7,11.6
			c3.3,7.5,3.7,18.3-11.1,28.7c0,0-15.1,9-21.8,24.2c-7,16.1,3.2,34.9,20.7,36.2c0.9,0.1,1.9,0.1,2.9,0.1c0,0-5.4-1.5-11-4.8
			c-9.4-5.7-12.8-17.6-8.5-27.7c2.9-6.8,8.6-15.4,19.6-22.9c0,0,12.4-6.3,16.2-19.9c2.8-10.1-1.2-20.9-9.5-27.2
			c-0.2-0.1-0.4-0.3-0.5-0.4c-6.6-4.8-11.6-11.7-13-19.7c-0.7-3.9-0.7-8.5,0.6-13.5c1.7-6.6,5.6-12.5,10.8-17
			c3.6-3.1,8.6-6.5,14.8-8.5c6.9-2.3,13.4-5.8,18.5-11c7.8-8,13.7-20.8,0.3-37.2c-2.2-2.7-5.1-4.8-8.2-6.5
			C173.5,322.1,154.5,307.3,166.7,270.3z"
          />
          <path
            class="st2"
            d="M142,606.5c0,0-8.3,39.8,47,31.7c0,0,2.7-0.4,6.4-0.2c13.7,0.8,22.2,14.7,17,27.4c-1.7,4.2-4.4,9.1-8.8,14.6
			c-4.2,5.3-7.1,11.8-7.2,18.6c-0.1,8,3.1,17.6,16.4,24.2c0,0,19.7,7.6,42.2-0.9c0,0,12.4-3,23.5,3c10.6,5.7,13.9,19.4,8.1,30
			c-0.9,1.6-2,3.3-3.3,5.1c0,0-21.6,35.6,27.8,46.5c0,0-32.1-7-26.1-36.3c0.9-4.3,2.7-8.3,5-12c1.4-2.3,3.2-5.7,4.7-9.6
			c3.7-10.2,0.8-22-8-28.4c-6.2-4.5-15.9-7.6-31.1-5.2c-1.6,0.3-3.1,0.6-4.7,1c-9.5,2.5-48.9,10.8-47.1-20.7c0,0,0-0.2,0-0.5
			c0.5-4.3,2.6-8.3,5.8-11.1c5.4-4.8,14.2-15.2,12-30.5c-0.6-4.5-2.5-8.9-5.6-12.3c-4.5-4.9-13.1-10.8-27.1-8.5
			C189,632.5,147.1,643.6,142,606.5z"
          />
          <path
            class="st1"
            d="M242.5,664c0,0-14.7,14-16.9,27.7c-1.1,6.5,3.7,12.7,10.3,13.2c5.7,0.4,14.6-0.6,28.5-5.5
			c0,0,22.9-9.2,37.9,8.9c4.1,4.9,6.7,10.9,7.9,17.2c1.5,8,1.8,20.3-5.6,33.1c-2.8,4.8-4.3,10.3-4.2,15.8
			c0.3,10.8,6.5,23.9,37.9,17.1c0,0-35.5,6.9-32.6-20.5c0.4-4.2,1.9-8.3,4-12c4.6-8.2,13.8-28.8,3.7-46.9c0,0-10.8-27.9-48.9-17.6
			C264.3,694.4,205.4,717.1,242.5,664z"
          />
          <path
            class="st1"
            d="M177.5,490.3c0,0-32.1,22.3-7.7,38.4c4.7,3.1,8.8,7,11.2,12.1c4.9,10.1,8.3,27.5-9.5,48.1
			c0,0-23.6,28.2,26.2,28.2c0,0,44.5-3.6,33.9,39c0,0,22.6-40.1-23.3-45.1c-4.6-0.5-9.3-0.4-13.9-0.1c-7.4,0.5-22.4,0.2-21.2-11.2
			c0.4-3.9,2.6-7.5,5.6-10c6.2-5.3,19.3-19.7,12.5-42.1c-2.9-9.4-9.4-17.2-17.3-23.1C166.7,519.2,159.4,508.4,177.5,490.3z"
          />
          <path
            class="st3"
            d="M192.8,482.3c0,0-24.1,23.6-9.8,35.2c0,0,21.9,11.7,22.7,36.8c0.2,6.9-1.5,13.8-4.6,20
			c-1.5,3-3.3,5.8-5.4,8.5c-2.6,3.3-14.5,12.3-9.7,18.2c3.2,3.9,11.1,2.9,15.3,2.5c0,0,31-6.7,42.4,14.9c0,0-3-24.3-42.9-18.7
			c0,0-17,2.3-7.2-9.7c0,0,9.8-8.6,13.9-19c0,0,14-31.8-18.5-54.9c-4.7-3.3-6.9-9.2-5.7-14.8C184.4,496.6,187,490.4,192.8,482.3z"
          />
          <path
            class="st1"
            d="M237.4,231.3c0,0-3.8,23.6-43.4,30.6c0,0-20.8,4.6-20.8,28.2c0,0-0.6,17.6,16.2,30c3,2.3,6,4.6,8.4,7.6
			c3.2,3.9,6.9,9.8,8.6,17.5c3.1,14-2.7,28.6-14.1,37.3c-3.8,2.9-8.6,5.7-14.7,7.9c-3.1,1.1-6.1,2.4-8.8,4.3
			c-9.2,6.4-25,22.3,0.1,44.1c0,0-22.2-21.6,0.3-38.4c3.3-2.5,7.1-4.2,11.1-5.5c2.7-0.9,6.7-2.5,10.9-5
			c17.2-10.1,25.8-30.9,19.4-49.8c-2.4-7.3-7.2-14.9-16-21.6c0,0-8.2-5.1-13-14.2c-8.3-15.6,1.2-35,18.6-38.5
			c0.4-0.1,0.8-0.2,1.2-0.2C201.5,265.5,234.7,259.9,237.4,231.3z"
          />
          <path
            class="st2"
            d="M243.9,212.9c0,0,7,5.7,8.7,16.3c1.1,7.2-1,14.4-5,20.5c-5.1,7.6-14.9,18.4-31.4,21.6
			c-3.8,0.7-7.5,1.7-10.9,3.5c-6.2,3.3-13.8,9.7-12,20.9c0.7,4.3,2.9,8.3,5.9,11.5c1.5,1.6,3.4,3.5,5.6,5.4
			c4.5,3.9,8.5,8.4,11.3,13.6c5.1,9.4,10.1,24.4,3.3,40.8c0,0,10.9-16.4,4-37.6c-2.9-8.9-9.1-16.2-16.5-21.9
			c-3.6-2.8-7.5-7.1-8.2-13.1c-0.8-7.8,4.3-15.1,11.8-17.4c1.3-0.4,2.8-0.7,4.5-0.9c0,0,22.4-1.9,35.5-23.6
			c4.3-7.2,6.3-15.7,4.3-23.8C253.3,223.3,250.3,217.5,243.9,212.9z"
          />
          <path
            class="st4"
            d="M294.3,174.6c0,0-17.6-5.2-31.9-0.7c-15.7,4.9-20,24.2-8.4,35.7c0.1,0.1,0.2,0.2,0.3,0.3
			c5.7,5.5,9.8,12.5,10.8,20.4c1.7,13.2-1.4,32.2-26.1,46.8c-5.1,3-10.7,5.2-16.4,7c-6.1,2-15.3,6.8-9.6,16.6
			c0,0-3.4-10.8,16.1-14.4c0,0,46.6-12.1,41.7-54.3c-1.1-9.4-5.9-17.8-12.6-24.5c-2.8-2.7-5.4-6.5-6.3-11.3
			c-1.1-6.5,2.4-13,8.2-16.2C266,176.8,276.5,173.6,294.3,174.6z"
          />
          <path
            class="st3"
            d="M490,173.1c0,0-68.3-104.2-160.8-80.4c-11.5,3-21.3,11.3-25,22.6c-3.7,11.4-2.8,27.1,15.7,44.5
			c0,0-14.2,3-42.5-4.8c0,0-32-9.8-48.6,8.8c-3.4,3.9-5.6,8.7-6.7,13.7c-2.5,11.3-3.6,33.6,21,46.2c0,0-20.7-16.7-15.6-40.2
			c1.7-7.9,6.5-14.8,13.5-18.8c6.3-3.6,16-6.7,29.6-5c3.4,0.4,6.8,1.3,10.1,2.3c8.4,2.5,30.6,7.8,49,0.3c0,0-31.8-29.7-13.8-51.1
			c3.2-3.8,7.6-6.5,12.3-8.2C347.8,96,413.7,82.3,490,173.1z"
          />
          <path
            class="st4"
            d="M486,193.5c0,0-54.7-85.2-132.1-81c-16.5,0.9-26.5,19.4-17.6,33.3c1.5,2.4,3.4,4.8,5.8,7.2c0,0-3-4.7-4-11.6
			c-1.5-10.2,6.9-20.6,17.3-21.3C383.7,118.1,429.5,129.4,486,193.5z"
          />
          <path
            class="st1"
            d="M493.9,190.7c0,0,33.9-73.6,77-86.3c14-4.1,29.3,1.8,36.6,14.4c0.5,0.8,0.9,1.6,1.4,2.5
			c2.8,5.6,7.4,10,12.8,12.9c14.7,7.9,44.1,17,87.7,0.7c0,0,29.4-13.8,36.8,5.6c0,0,19.1,43,82.9,19.9c0,0,35.3-14,56-10.7
			c7.8,1.2,13.3,8,13,15.9c-0.3,6.6-2.5,15.9-10.1,26.3c-1.9,2.5-3.4,5.3-4.1,8.3c-2,8.4-1.2,23.2,29.8,21.8c0,0-10.2,2.7-19.9,0.5
			c-11.8-2.8-18-16.1-13.4-27.3c0.7-1.6,1.5-3.2,2.5-4.9c0,0,7.4-10.5,9.6-20.5c1.5-6.7-3.3-13.1-10.1-14
			c-9.1-1.1-25.4-0.2-52.1,10.5c0,0-57.7,26.9-88.8-19.7c0,0-6.7-15.2-30-6.2c0,0-44,22.1-86.7,2.9c-9.1-4.1-16.7-10.9-22.1-19.2
			l-0.2-0.3c-6.8-10.3-19.7-14.8-31.4-10.8C553.7,119.1,524.2,137.1,493.9,190.7z"
          />
          <path
            class="st1"
            d="M727.7,170.3c0,0-29.8,55.8,28,36.8c7-2.3,14.3-3.2,21.6-2.4c0.2,0,0.4,0,0.5,0.1
			c24.4,2.9,42.5,24.2,41.7,48.7c-0.3,9.5-1.8,21.2-5.7,34.4c0,0-11.7,29.9,38.5,25.5c0,0,44.9-3.6,56.9,24.8
			c2.3,5.3,2.6,11.2,1.9,16.9c-1.3,11,0.7,34.6,32.4,32.8c0,0-33.5,1.6-27.1-35.2c1.2-7.1,0.4-14.6-2.7-21.1
			c-5.9-12.3-22.2-27.1-62.1-24.4c0,0-39.3,5.6-30.8-21.9c0,0,8.1-21.7,5.1-43.5c-2.6-18.9-14.1-34.5-32.2-40.6
			c-6.6-2.2-14.4-3.7-23.2-3.2c-5.7,0.3-11.2,1.9-16.5,3.9C742.4,206.3,715.5,212.7,727.7,170.3z"
          />
          <path
            class="st2"
            d="M826.7,192.6c0,0,25.2,5.6,24.9,43.8c0,5.7-0.8,11.3-1.9,16.9c-1.3,6.7-3.6,16.1-7.1,23.6c0,0-8,18.4,21,16
			c0,0,70.5-8,70.5,52.4c0,0-7.3,35.3,32,30.2c0,0,32.6-3.5,15.5,42.1c0,0-14.5,29.5,8,41.7c10.1,5.5,15.1,17.5,10.1,27.8
			c-3.7,7.6-11.9,16.5-28.1,25.8c0,0,25.5-5.9,33.6-24.8c5.2-12.2-0.6-26-11.9-33c-8.2-5.1-16.6-15.6-6.8-36.2c0,0,5.7-11.3,6-24.3
			c0.3-13.3-9.6-24.4-22.8-25.1c-1.4-0.1-2.8,0-4.4,0.1c0,0-29.2,3.6-24.5-25.1c0,0,10.2-54.9-66.1-59c-3.8-0.2-7.6-0.2-11.4,0
			c-4.3,0.2-13.6-0.4-10.6-10.6C853,274.9,875.3,197.2,826.7,192.6z"
          />
          <path
            class="st2"
            d="M857,330.4c0,0,33.3-3.4,37.4,23.8c1.1,7.4-0.7,14.9-4.4,21.4c-5.4,9.4-11.5,27.7,16.9,34.1
			c0,0,35.9,3.6,34.9,30.9c-0.1,3.8-1.3,7.5-2.9,11c-2.1,4.7-4,14.1,9.8,20.8c9.9,4.9,15.6,15.9,12.4,26.5
			c-2.4,7.8-8.4,16.6-21.9,24.7c-3.3,2-6.6,4.2-9.3,6.9c-7.2,7.2-16.2,20.8,3.6,32.4c0,0-16.9-12.6-1.8-29.4c2.7-3,6.1-5.3,9.7-7.1
			c7-3.4,21-11.9,25.8-25.8c4.2-12.3-2.4-25.4-13.9-31.4c-7.1-3.7-13.8-9.8-7.7-18.9c0,0,16.6-37.1-33.6-45.4
			c-3.2-0.5-6.3-1.3-9.2-2.5c-7.1-3.1-16.6-9.9-8.4-23.2c4.9-7.9,7.3-17.4,5.2-26.5C896.8,340.7,886.9,328.2,857,330.4z"
          />
          <path
            class="st1"
            d="M851,339.5c0,0,28.9,10.8,16.8,41.7c-1.7,4.5-4.4,8.6-7.3,12.4c-5,6.6-13,20.4,6.6,22.9
			c0,0,22.7,4.6,10.9,26.8l-6.7,1.2c0,0,13.2-18.5-8.6-24.1c0,0-27.8-2.2-4.6-32.5C858.2,388,878.8,363.1,851,339.5z"
          />
          <path
            class="st1"
            d="M966.3,535.9c0,0,20.5,23.7-16.1,34.8c-6.5,2-12.7,4.8-18.2,8.8c-11.5,8.4-24,23.9-9,46.6c0,0,6,7.4,9.3,17.9
			c5.2,16.9-2.4,35.1-17.7,43.8c-4.3,2.5-9.7,5.1-16,7.1c-10.5,3.5-20.1,11.9-22.7,22.7c-2,8.2-2.6,17.5,8.8,28.9
			c4.3,4.3,6.7,10.2,7.4,16.3c1.1,9.4-2.1,21.2-23.7,23.4c0,0,21.6-3.8,20.5-21.5c-0.3-5.6-3.2-10.9-7.5-14.6
			c-6.6-5.8-17.8-19.2-8.5-38.8c4.9-10.3,14.1-17.8,24.8-21.8c11.4-4.2,27.7-13.2,29.6-30.3c0.4-3.5,0-7-0.9-10.3
			c-1.2-4.9-3.8-12.8-8.5-19.3c0,0-23.6-26.8,8.8-52.5c0,0,10-7.3,26.4-12.4c3.1-1,6.2-2.3,8.7-4.3
			C967.1,556.4,972.8,548.6,966.3,535.9z"
          />
          <path
            class="st2"
            d="M886.4,523c0,0,30.5,19.1,5.9,56.2c0,0-28,31-3.1,47.9c6,4.1,11.1,9.8,12.8,16.9c1.5,6.4,1,14.6-5.7,23.6
			c-3.6,4.9-8.4,8.8-13.7,11.8c-11.7,6.8-40,26-33.6,50.2c1.4,5.3,4.6,9.8,8.6,13.5c5.6,5.2,14.5,15.9,6.9,26.8
			c-2.6,3.7-6.4,6.2-10.6,7.8c-10,3.7-34.9,15.1-26.2,36.6c0,0-17.5-23.8,23.2-39.4c4.4-1.7,8.2-4.8,10.3-9c2.7-5.3,3.2-13-7.1-21
			c-2.6-2.1-5.1-4.4-7-7.2c-5.9-9-12-28.3,18.3-53.5c0,0,32.4-17.4,27.8-40.5c0,0-0.4-1.9-1.8-4.5c-1.5-2.9-3.9-5.2-6.6-7
			c-7.1-4.7-24.4-20.9,2.4-56C887.4,576.1,909.8,546.4,886.4,523z"
          />
          <path
            class="st2"
            d="M997.4,539.9c0,0,15.9,3,24.8,12.2c7.5,7.8,5.8,20.9-3.2,26.9c-5.5,3.6-14.3,6.7-28.2,6.5
			c-11.5-0.2-22.9,3.2-31.6,10.6c-0.1,0.1-0.2,0.2-0.3,0.3c-7.7,6.7-10.8,17.2-8.6,27.2c1.5,6.9,4.7,16.2,11.4,24.1
			c0,0,15.1,14.1,5.9,40.5c0,0-7,21-32.7,22.3c-12,0.6-23.4,6.4-29.2,16.9c-4.3,7.8-6,18.4-0.1,32.2c0,0-15.8-23.2,2.6-43
			c6.1-6.6,14.6-10.6,23.5-11.8c9.5-1.3,24.6-5.7,29.8-21c1.6-4.7,1.8-9.7,0.9-14.6c-0.7-4.4-2.4-9.9-6-15.6c0,0-8.9-10.3-12.3-25
			c-3.4-14.7,2.2-30.1,14.2-39.2c3.8-2.9,8.5-5.6,14-7.5c4.3-1.4,8.8-2,13.3-2c6.9-0.1,20.3-0.8,29.3-5.1c7.9-3.8,10-13.7,4.4-20.4
			C1015.6,550,1009.1,544.9,997.4,539.9z"
          />
          <path
            class="st1"
            d="M849,528c0,0,19.2,29.6-2.4,60.9c0,0-20.3,24.5,10.1,39.2c4.8,2.3,8.6,6.4,10,11.5c2.3,8.4-0.8,21.3-28.5,35
			c0,0,28.8-15.8,23.8-33.2c-1.2-4.2-4.7-7.3-8.7-9c-9.4-3.9-29.5-16.4-11.7-46.9c0,0,20.5-28.9,1.4-55.8L849,528z"
          />
          <path
            class="st1"
            d="M832.7,649.8c0,0-50.3,38.2-17.2,57.8c0,0,24.5,20.1-7.9,48.6c0,0-31.2,28-8.1,48c7.5,6.5,7.1,18.4-0.4,24.9
			c-2.4,2.1-5.6,4.2-9.6,6.3c0,0,6.2-5.2,10.2-11.6c3.5-5.6,2.2-13-2.9-17.2c-7.8-6.4-21.8-23.8,7.9-53.5c0,0,19-15,13.4-32.8
			c-1.4-4.5-4.4-8.3-8.2-11.1C801.9,703.4,784.8,684.3,832.7,649.8z"
          />
          <path
            class="st4"
            d="M797.4,715.7c3.6,0.1-6.9,0.4-10,0.2c-23.1-1.6-36.9-26.8-26.9-47.7c5.7-11.9,12.1-17.9,17.2-22
			c2.9-2.3,5.7-4.8,8-7.8c23.6-29.8-7.8-52.6-7.8-52.6l4.6-7.5c0,0,25.9,18.4,15.7,50.6c0,0-3.6,11.5-16.1,21.8
			c0,0-10.6,6.5-16.5,20.8c-3.3,7.9-4,16.8-1,24.7C767.9,705.1,776.4,714.9,797.4,715.7z"
          />
          <path
            class="st1"
            d="M727.3,619.6c0,0,28.3,11.3,4,42.9c0,0-36.7,46.6,2.6,59.7c5.7,1.9,10.8,5.2,14.2,10.1
			c6.6,9.6,10.5,26.8-17,50c0,0-13.7,13.7-2.5,24.8h-6.2c0,0-12.5-12.3,6.2-28.9c0,0,27-19.6,17.1-40c-2.7-5.6-8.1-9.3-14.1-10.9
			c-12.4-3.2-36.7-15.3-9.8-59.2c1.8-2.9,3.7-5.8,5.8-8.6C732.1,653.2,746.5,630.8,727.3,619.6z"
          />
          <path
            class="st2"
            d="M700.6,668.2c0,0-26.2,51.6,1.2,64.4c2.6,1.2,5.3,2.5,7.4,4.5c6.3,5.6,14.4,18.1-1.9,39.2l-3.1-2.4
			c0,0,15.5-16.4,3.7-31c-2.1-2.6-5-4.5-8-5.9C691.9,733.2,671.8,718.2,700.6,668.2z"
          />
          <path
            class="st1"
            d="M634.5,656.3c0,0,25.3,8.2,8.7,41.6c0,0-17.9,30.1,20.3,39.5c8.1,2,13.5,9.9,11.8,18.1
			c-1.1,5.2-4.3,11.6-11.2,19.5c0,0,6.9-11.4,7.3-21.6c0.3-5.9-4-11-9.8-12.1c-12.9-2.5-40.4-11.8-23.5-44.4c0,0,15.6-26.4-6.8-37.5
			L634.5,656.3z"
          />
          <path
            class="st2"
            d="M445.6,689.3c0,0-20.7,26.5,6.8,32.6c0,0,20.5-0.2,20.5,26.9l5.3-2.1c0,0-0.8-27.9-22.9-29.2
			C455.4,717.4,431.7,717.4,445.6,689.3z"
          />
          <path
            class="st1"
            d="M288.8,692.5c0,0,53.7-8.3,42.9-47.9c0,0-2-9.6-15.5-21.4c-2.4-2.1-4.7-4.4-6.4-7.1c-4.7-7.3-9.9-21.6,7-35.5
			l-10.6-10.1c0,0-0.3,0.3-0.8,0.8c-16.3,15.6-15.4,41.8,1.5,56.9C322.4,642.3,339.9,668.7,288.8,692.5z"
          />
          <path
            class="st5"
            d="M475.7,223.5c0,0-76.1-70.3-146-48.7c-13.4,4.1-21.3,18.3-20.8,32.3c0.2,6.2,1.3,13.2,4.3,20.5
			c2.7,6.8,3.4,14.3,1.4,21.3c-2.5,8.9-9.6,19-27.5,23.3c0,0-36.9,9.4-15.2,45.3c0,0,23.1,30.9-9.5,46.4c0,0-21.5,6.8-24.5,29.9
			c-1.2,9.4,1.5,18.9,6.7,26.9c1.7,2.6,3.9,5.6,6.6,8.8c0,0,22.4,25,5.3,57.9c0,0-15.6,31.6,10,41.7c2.5,1,5.1,2.1,7.3,3.6
			c8.3,5.7,22.7,20.6,6,49.4c0,0-18.5,23.3,11.3,47.9c0,0,29.1,18.8,5.5,53.2c0,0,24.4-18.7,4.5-49.1c-2.8-4.3-6.4-8-10.3-11.5
			c-6.4-5.8-17.7-19.6-5.6-38.3c0,0,24-28.1-5.8-54.4c-2.8-2.5-8-5.8-11.3-7.6c-4.6-2.6-12.5-9.3-9.7-23.7c0.8-4,2.6-7.8,4.9-11.2
			c4.7-7.2,13.1-25.3-0.3-50.1c-2.5-4.6-5.5-8.8-8.9-12.7c-3-3.5-8.2-10.6-10.1-20.1c-3.1-15.2,5.2-30.8,19.8-36
			c0.1,0,0.3-0.1,0.4-0.1c0,0,34.5-10.8,12.9-52.4c0,0-16.5-20.4,4.8-34.7c3.4-2.3,7.2-3.7,11.2-4.4c7.2-1.3,14.2-4.7,19.2-10.1
			c7.6-8.2,10.1-20.3,9.2-31.2c-0.8-9.7-6.5-18.7-6-28.5c0.2-4.5,1.5-8.9,3.9-12.6c7.8-11.8,24.1-14.5,37.1-15.2
			C356.6,179.1,408.8,176.8,475.7,223.5z"
          />
        </g>
        <g class="ears">
          <g id="left-ear_2_">
            <circle class="st6" cx="283.1" cy="451.2" r="37.1" />
            <path class="st7" d="M317.5,453.8c0,0-23.7,15.5-40.7-2.8" />
            <path class="st7" d="M288.4,435.2c0,0-20.5,13-8.3,31.6" />
          </g>
          <g id="right-ear_2_">
            <circle class="st6" cx="840.2" cy="451.2" r="37.1" />
            <path class="st7" d="M804.1,453.2c0,0,23.4,15.9,40.7-2" />
            <path class="st7" d="M833.5,435.1c0,0,20.3,13.4,7.7,31.7" />
          </g>
        </g>
        <g id="head-shadow" class="shadow">
          <circle class="st8" cx="561.4" cy="491.4" r="259.3" />
        </g>
        <circle id="head_2_" class="st9" cx="561.4" cy="451.2" r="258.1" />
        <g id="cheeks">
          <defs>
            <filter id="f1" x="0" y="0">
              <feGaussianBlur in="SourceGraphic" stdDeviation="20" />
            </filter>
          </defs>
          <g>
            <ellipse
              style="fill:#FFDEDE;"
              cx="350"
              cy="300"
              rx="250"
              ry="170"
              transform="matrix(0.24 0 0 0.24 316.5217 470.5757)"
              filter="url(#f1)"
            ></ellipse>
          </g>
          <g>
            <ellipse
              style="fill:#FFDEDE;"
              cx="350"
              cy="300"
              rx="250"
              ry="170"
              transform="matrix(0.24 0 0 0.24 621.5618 470.5757)"
              filter="url(#f1)"
            ></ellipse>
          </g>
        </g>
        <g class="bangs">
          <g id="tiny-hairs">
            <path
              id="tiny_left_1_"
              class="st10"
              d="M373,330.2c-4.1,2.3-7.7,5.3-10.7,9c-6.8,8.4-7,15.6-6.1,19.9c0.4,4.4,1.8,8.2,3.5,11.3
				c4.8,8.5,12.5,12.6,12.5,12.6c0.4,0.2,0.8,0.4,1.1,0.6c3.1,1.7,5.6,4.3,7.5,7.3c6.5,10.3,4.3,20.1,3.5,22.8
				c6.9-21.4-9.9-31.4-9.9-31.4c-11.7-7-15.1-17.1-15.9-24.3c-0.7-5.9,1.1-11.8,4.8-16.5c0,0,3-5.2,10.2-8.8
				c2.6-1.3,5.1-2.8,7.3-4.7c6.9-5.8,17.5-18.3,10.8-37.2H390C390,290.9,398.1,316.2,373,330.2z"
            />
            <path
              id="tiny_right"
              class="st10"
              d="M772.1,344.5c0,0,3.8,2.9,7.5,7.6c3.6,4.7,5.4,10.6,4.8,16.5c-0.8,7.2-4.2,17.3-15.9,24.3
				c0,0-16.8,10-9.9,31.4c-0.8-2.7-3-12.6,3.5-22.8c1.9-3,4.4-5.6,7.5-7.3c0.4-0.2,0.7-0.4,1.1-0.6c0,0,7.8-4.1,12.5-12.6
				c1.8-3.2,3.1-6.9,3.5-11.3c1.1-5.5,0.6-16-13.8-27.3L772.1,344.5z"
            />
          </g>
          <g id="bigger-bangs">
            <path
              class="st11"
              d="M493.2,282.9c0,0-22.1-21.5-63.7-17.1c-8.4,0.9-21.4,6.5-25.8,24.1c-0.4,1.7-4.9,14.5-9,21.3
				c0,0-8.5,13.7-25.7,14.6c-6,0.3-18.9-1.4-24.1,1.6c-9.8,5.8-19.5,17.6-6.7,40.6c0,0,21,26.2-8.5,43.2c0,0-31.7,18.6-4,46.3
				c0,0,30.6,21.5,6.6,60.8c0,0-10.9,16.8,11.6,30.2c0,0,30.3,19.6-6,55.9c0,0-21.5,22,2,42.9c2.7,2.4,5.3,4.8,7.6,7.6
				c9.4,12,25.4,41.5-26.8,60.9c0,0,19.8-20.2,10.3-42.9c-2.4-5.9-6.8-10.7-11.9-14.5c-9.9-7.4-30.9-27.9-10.9-57.7
				c2.7-4.1,4.7-8.7,5-13.5c0.3-6.4-1.6-14.9-10.7-23.1c-2.6-2.3-5.6-4.1-8.7-5.6c-6.6-3.4-20.8-13.7-15.6-38.9
				c1-4.9,2.8-9.6,4.8-14.2c3.6-8.3,10.3-30.9-8.9-56.5c0,0-32.3-41.1,16.1-65.6c0,0,12.6-3.9,15.5-18.3c1.4-6.8-0.5-13.8-4.4-19.5
				c-8.7-12.9-24.1-43.9,16.3-58.1c0,0,0.5-0.1,1.3-0.4c13.8-4.8,21.6-19.5,18-33.6c-4.2-16.5-15.1-60.3,18.4-72.1
				c5.2-1.8,10.8-2.6,16.3-2.7c21.4-0.5,56.9-6.3,119.4,22.4L493.2,282.9z"
            />
            <path
              class="st0"
              d="M479.3,253.1c0,0-12.8-26.2-68.9-11.7c-18.9,4.9-33.2,20.3-35.2,39.8c-0.5,4.7-0.5,9.9,0.4,15.5
				c0.5,3,0.3,6.1-0.9,8.9c-2,4.5-7.1,9.8-19.6,11c-9.2,0.9-17.9,4.8-23.6,12.1c-5.6,7.1-9.5,17.5-4.5,31c1.1,2.9,2.7,5.7,4.3,8.3
				c3.8,6.1,12,21.9,3.6,33.2c0,0,5.2-13.7-10.2-33.6c0,0-11.6-14.7-3.8-36c2.4-6.5,6.8-12.1,12.6-15.7c0.1-0.1,0.2-0.1,0.3-0.2
				c5.6-3.5,12.2-4.8,18.9-4.9c1.1,0,2.4-0.1,3.7-0.2c8.2-1.1,14-8.6,13-16.8c-0.5-3.7-0.8-9.1-0.1-15.5
				c2.2-20.6,16.8-37.6,36.4-44.2C430.4,225.7,458.8,223.8,479.3,253.1z"
            />
            <path
              class="st5"
              d="M474,226.2c0,0-42.2-47.3-112.1-25.7c-13.4,4.1-21.3,18.3-20.8,32.3c0.2,6.2,1.3,13.2,4.3,20.5
				c2.7,6.8,3.4,14.3,1.4,21.3c-2.5,8.9-9.6,19-27.5,23.3c0,0-36.9,9.4-15.2,45.3c0,0,23.1,30.9-9.5,46.4c0,0-21.5,6.8-24.5,29.9
				c-1.2,9.4,1.5,18.9,6.7,26.9c1.7,2.6,3.9,5.6,6.6,8.8c0,0,22.4,25,5.3,57.9c0,0-15.6,31.6,10,41.7c2.5,1,5.1,2.1,7.3,3.6
				c8.3,5.7,22.7,20.6,6,49.4c0,0-18.5,23.3,11.3,47.9c0,0,29.1,18.8,5.5,53.2c0,0,24.4-18.7,4.5-49.1c-2.8-4.3-6.4-8-10.3-11.5
				c-6.4-5.8-17.7-19.6-5.6-38.3c0,0,24-28.1-5.8-54.4c-2.8-2.5-8-5.8-11.3-7.6c-4.6-2.6-12.5-9.3-9.7-23.7c0.8-4,2.6-7.8,4.9-11.2
				c4.7-7.2,13.1-25.3-0.3-50.1c-2.5-4.6-5.5-8.8-8.9-12.7c-3-3.5-8.2-10.6-10.1-20.1c-3.1-15.2,5.2-30.8,19.8-36
				c0.1,0,0.3-0.1,0.4-0.1c0,0,34.5-10.8,12.9-52.4c0,0-16.5-20.4,4.8-34.7c3.4-2.3,7.2-3.7,11.2-4.4c7.2-1.3,14.2-4.7,19.2-10.1
				c7.6-8.2,10.1-20.3,9.2-31.2c-0.8-9.7-6.5-18.7-6-28.5c0.2-4.5,1.5-8.9,3.9-12.6c7.8-11.8,24.1-14.5,37.1-15.2
				C388.8,204.9,438.9,199,474,226.2z"
            />
            <path
              class="st11"
              d="M491,201.3c0,0,83.9-73.6,185.9-37.2c19,6.8,29.4,27.7,23.4,47c-0.1,0.3-0.2,0.6-0.3,0.9
				c0,0-3.6,13.2,10.1,18.5c5,1.9,10.4,1.9,15.6,0.8c12.9-2.9,47.1-7.8,62.9,16.8c3.7,5.8,5.5,12.6,6,19.4c0.3,3.5,0.3,7.6-0.2,12
				c-0.5,5-2.1,9.8-3.9,14.5c-2,5.4-4.8,19.1,13.6,29.4c3,1.7,6.3,2.8,9.7,3.8c8.9,2.5,31.2,11.6,31.5,40.1
				c0.1,12.9-4.1,25.6-12.1,35.6c-2.2,2.8-4.9,5.6-8,8.1c0,0-17,14.6-0.4,27.7c0,0,35.2,31.5,9.4,75.8c-3.1,5.4-6.9,10.2-11.1,14.9
				c-6.4,7.2-22,29.5-2.7,58.9c0,0,24.2,40.1-22.9,72c-5.9,4-10.8,9.4-14,15.8c-5.5,10.8-7.4,26.6,14.9,39.8c0,0-43,0-34.3-37.1
				c2.6-11.3,9.7-20.9,18.9-27.9c7.1-5.4,15.9-14.9,18-29.6c2.4-16.6-5.1-33.3-18.5-43.5c-1.2-0.9-2.5-1.8-3.8-2.7
				c0,0-31.8-23.9-8.9-54c3.4-4.5,7.7-8.2,12.2-11.4c5.1-3.6,13.4-10,19.7-17.7c10.3-12.6,7.4-31.6-6.5-40.1
				c-0.1-0.1-0.2-0.2-0.4-0.2c0,0-22.9-7.3-24.2-25.6c-0.5-6.6,2.5-12.9,7.2-17.6c5-4.9,12.7-13.7,16.5-24.4
				c5.5-15.5-5.7-32.1-22.1-33.6c-3.4-0.3-7.3-0.3-11.4,0.1c0,0-16.5,2.5-25.7-8.7c-3.8-4.7-5-10.9-4.2-16.9
				c0.5-3.6,1.5-7.9,3.7-12.2c2.7-5.4,4-11.6,2.9-17.5c-1.5-7.4-6.6-15.8-21.4-19.2c-5.7-1.3-11.8-0.8-17.4,0.8
				c-18.1,5.3-65.1,15.6-69.6-18.7c0,0-5-36.5-48.4-34.3c-11.4,0.6-22.3,4-32.4,9.2c-15.6,8.1-40.3,23.7-55.2,49.8L491,201.3z"
            />
            <path
              class="st5"
              d="M500.5,220.4c0,0,76.2-82.3,164.7-54.8c0,0,35.4,11.1,24.7,46.1c0,0-7.4,32.3,38.5,25.7
				c0,0,35.1-10.9,52.3,10c4,4.9,6.1,11,6.7,17.3c0.4,4.6,0.4,10.5-1,17.5c-0.9,4.1-2.2,8.1-3.9,12c-2.2,5.3-7.4,22.8,9.1,30.7
				c3.3,1.6,6.7,3.2,10.3,4c11.7,2.6,49.8,14.8,31.1,59.3c0,0-4.5,10.6-15.2,18.2c0,0-13.8,8.8-7.6,24.5c1,2.5,2.6,4.7,4.5,6.6
				c6.3,6.4,23.4,25.9,20.8,51c-1.1,11.2-6.9,21.4-15.1,29.1c-6.8,6.5-17.8,17.7-20,32.8c-1.3,9.3,1.6,18.6,6.8,26.3
				c9,13.2,28.3,50-11.9,77c0,0-37,28.2-11.8,53.4c0,0-37.7-21.6,8.5-58c0,0,39.7-29.2,6.9-72.1c0,0-24.3-31.4,15.8-65.7
				c7.3-6.3,11.9-15.3,12.5-25c0.7-12.1-2.9-28.7-20.4-46.2c0,0-21-16.7,8.3-42.1c0,0,22.2-20.6,14.2-40.4c-1.5-3.8-4.1-7-7.2-9.6
				c-4.4-3.6-15.5-10.7-27.1-12.6c0,0-36.4-4.1-21.6-39.9c1.3-3.1,2.5-6.3,3.4-9.5c1.2-4.6,1.6-11,1.8-17.5
				c0.4-12.6-12.9-24.1-23.8-25.6c-5.8-0.8-13.7-0.8-23.8,1.2c0,0-51.3,9.1-50.7-24.9c0.1-4.1,1-8,2.3-11.9
				c2.6-7.4,6.2-28-27.1-37.6C655.5,169.8,592.8,148.3,500.5,220.4z"
            />
            <path
              class="st0"
              d="M493.9,253.9c0,0,64.3-66.1,124.8-48.7c16,4.6,25.9,20.7,29,37c0.4,2,0.7,4.2,1,6.5
				c1.2,10.8,10.2,19.2,21.1,18.9c4.3-0.1,9.3-1,15.6-2.3c1.6-0.4,3.3-0.8,4.9-1.2c5.5-1.4,20.4-4.9,31.4-4.7
				c27.6,0.6,42.7,32.5,28.2,56c-0.9,1.4-1.8,2.8-2.9,4.3c-1.6,2.2-2.7,4.7-3.1,7.4c-0.9,6.3,0.4,11.7,19.3,12.1
				c0,0,6.3-0.4,14.3,1.2c23.1,4.8,34.5,30.1,23.7,51c-2.5,4.7-5.9,9.4-10.8,13.7c-5.4,4.8-9.5,11.2-10,18.4
				c-0.5,7.8,2.7,16.8,17.7,21.6c0,0,10.5,3,15.7,16c3,7.6,2.8,16,0.2,23.8c-3.1,9.1-10.1,21.8-25.8,30.6c-6.6,3.7-12.3,8.8-16,15.3
				c-5.9,10.7-8.3,26.9,14.9,43c0,0-36.4-13.9-18.7-46.6c3.4-6.3,8.6-11.5,14.5-15.5c11.8-8,34.3-26.9,26.2-49.2
				c0,0-1.9-8.1-12.1-13.1c0,0-25.9-8.8-22.8-28c0.6-3.5,2.1-6.7,4.2-9.5c2.4-3.3,6.6-8.8,11.4-13.6c0,0,6.9-7.5,9-18.8
				c2.3-12.1-3.1-24.6-14-30.3c-4.9-2.6-11.4-4.2-19.9-3.4c0,0-11.1,0.4-20.1-3.7c-8.7-4.1-12.1-14.8-7.8-23.4
				c2-4.2,4.6-10.3,6-17.2c2.6-13-4.8-26.1-17.6-29.8c-7.9-2.3-18.7-2.8-32.9,1.5c0,0-16.3,4.1-31.3,3.1c-13-0.9-23-11.8-23.5-24.9
				c-0.3-7.9-2.8-19.1-12.6-28.8C625.3,222.7,582.7,185,493.9,253.9z"
            />
            <path
              class="st0"
              d="M493.2,282.9c0,0,25.5-56.6,93.3-60.7c0,0,41.2-1.9,45.2,46.6C631.7,268.9,593.8,221.1,493.2,282.9z"
            />
          </g>
          <g id="bangs-shadow" class="st12">
            <path
              class="st13"
              d="M605.5,287.4c0,0,3.7,27.1,30,16.5c0,0,27.8-12.9,48.9,4.4c8.5,6.9,13.5,17.4,14.2,28.3
				c0.5,7.6-0.4,17.6-5.5,28.8c0,0-13,21.8,27.8,31.4c0,0-29.6,2.2-35.7-20.4c-1.4-5.3-0.8-11,0.9-16.2c2.4-7.3,5.5-21.4-1.7-34
				c-4.6-8-12.7-13.6-21.8-15.5c-5.9-1.2-13.8-1.5-22.8,1.3c0,0-23.4,10.4-38.6-11.4l-3.6-12.2L605.5,287.4z"
            />
            <path
              class="st13"
              d="M483.2,290.2c0,0,17.8-7.2,34.6-2.1c13.3,4.1,21.6,16.9,21,30.8c-0.2,3.8-0.8,8-2.1,12.7
				c-1.4,4.8-2,9.9-1.5,14.9c1.3,11.2,7.3,27.8,31.3,27.8c0,0-33.8,4.1-40.8-22.9c-1.9-7.5-0.9-15.5,2.1-22.6
				c0.9-2.2,1.8-4.8,2.5-7.7c2.1-9.5-2.4-19.1-10.8-24C511.6,292.5,499.1,287.8,483.2,290.2z"
            />
            <path
              class="st13"
              d="M482.8,289.8c0,0,23-15.4,50.1-12c28.8,3.6,48.5,31.8,41.5,60.1c-0.1,0.3-0.1,0.5-0.2,0.8
				c-1.2,4.5-2.8,8.8-4.7,13c-2.6,5.9-7.2,22.3,16.8,30.3c5.8,1.9,11.3,5,15.6,9.3c9.1,9.1,16.5,26-8.5,51.4c0,0,19.3-24.9,1-41.1
				c0,0-9-6.2-17.6-7.1c0,0-26.1-2.5-30.1-25.9c-1-6-0.1-12.1,2-17.7c1.5-4.2,3.9-9.7,7-15c2.5-4.2,4.1-8.9,4.5-13.8
				c0.8-9-1.4-21.8-17.2-29.3C543,292.8,517.4,278.6,482.8,289.8z"
            />
            <path
              class="st13"
              d="M392.6,370c0,0-7.8-18.8,6.3-25.4c0,0,6.3-1.7,12.3-0.9c0,0,26.6,2.1,21.7-26.6c0,0-0.1-0.3-0.3-0.7
				c-4.9-12.8,3.4-26.8,17-28.9c7.5-1.2,18.6-1.3,33.6,2.6c0,0-14.1-9.6-30-11.5c-13.3-1.5-26.4,5-32.6,16.9c-3,5.8-4.6,13-0.7,20.7
				c0,0,7.8,17.9-20.3,19C399.5,335,373.3,339.4,392.6,370z"
            />
            <path
              class="st13"
              d="M482.2,290c0,0-16.9-6.9-32.7-2c-12.6,3.8-20.5,16-19.9,29.2c0.2,3.6,0.7,7.6,2,12.1
				c1.3,4.6,1.9,9.4,1.4,14.1c-1.2,10.6-6.9,26.3-29.7,26.3c0,0,32,3.9,38.6-21.7c1.8-7.1,0.8-14.6-2-21.4c-0.9-2.1-1.7-4.6-2.3-7.3
				c-2-9,2.2-18.1,10.2-22.7C455.3,292.1,467.1,287.7,482.2,290z"
            />
            <path
              class="st13"
              d="M483.2,290c0,0-11-4.5-21.4-1.3c-8.2,2.5-13.4,10.5-13,19.1c0.1,2.3,0.5,5,1.3,7.9c0.9,3,1.3,6.1,0.9,9.2
				c-0.8,6.9-4.5,17.2-19.4,17.2c0,0,20.9,2.5,25.2-14.2c1.2-4.7,0.5-9.6-1.3-14c-0.6-1.3-1.1-3-1.5-4.8c-1.3-5.9,1.5-11.8,6.7-14.8
				C465.6,291.4,473.4,288.5,483.2,290z"
            />
            <path
              class="st13"
              d="M610,351.4c0,0-22-14.2-10.6-36.8c0,0,18.5-27.3-10.6-44.4c0,0-22.8-14.1-74.1,7.2c0,0-20.3,7.6-31.6,12.7
				c0,0,50.5-13,83.1-9.1c0,0,19.8,4.2,21.4,18.1c0.6,5.6,0.4,11.2-1,16.6C584.4,324.2,582.3,344.4,610,351.4z"
            />
            <path
              class="st13"
              d="M773,374.3c-3-7.5-8.5-12.4-13.9-11.4c-2.7,0.5-3.8,1.7-6.3,2.7c-1.9,0.7-3.7,1-5.4,1.3
				c-27.1,5-49-5.7-43.3-28.3c0.8-3.1,1.8-5.8,2.7-8.2c3.7-9.1,0.4-21.8-9.3-23.5c-7.4-1.4-17.2,1.6-17.2,1.6
				c-49.2,14.1-61.3-0.6-68.2-9.1c-6.4-7.9-14.1-23.7-23.9-26.4c-42.8-11.8-101.2,15.1-105,17c4.8-3.3,106.8-71.2,136.6-17.9l0,0
				c9.6,25.9,51.2,16.7,67.9,11.8c5.7-1.7,11.7-2.2,17.4-0.9c14.8,3.4,19.9,11.7,21.4,19.2c1.2,6-0.1,12.1-2.9,17.5
				c-2.2,4.4-3.3,8.7-3.7,12.2c-0.8,6,0.4,12.2,4.2,16.9c9.1,11.2,25.7,8.7,25.7,8.7c4.2-0.4,8.6-1.5,10.9-1.2
				C769.9,357.7,770.6,363,773,374.3z"
            />
            <path
              class="st13"
              d="M493.7,283.4c0,0-19.9-18.9-50.9-18.1V279c0,0,20.6-1.7,40.4,11L493.7,283.4z"
            />
          </g>
          <g id="tiny-bangs-on-forehead">
            <path
              class="st0"
              d="M615.5,280.3c0,0,3.7,27.1,30,16.5c0,0,27.8-12.9,48.9,4.4c8.5,6.9,13.5,17.4,14.2,28.3
				c0.5,7.6-0.4,17.6-5.5,28.8c0,0-13,21.8,27.8,31.4c0,0-29.6,2.2-35.7-20.4c-1.4-5.3-0.8-11,0.9-16.2c2.4-7.3,5.5-21.4-1.7-34
				c-4.6-8-12.7-13.6-21.8-15.5c-5.9-1.2-13.8-1.5-22.8,1.3c0,0-23.4,10.4-38.6-11.4l-3.6-12.2L615.5,280.3z"
            />
            <path
              class="st10"
              d="M493.2,283.1c0,0,17.8-7.2,34.6-2.1c13.3,4.1,21.6,16.9,21,30.8c-0.2,3.8-0.8,8-2.1,12.7
				c-1.4,4.8-2,9.9-1.5,14.9c1.3,11.2,7.3,27.8,31.3,27.8c0,0-33.8,4.1-40.8-22.9c-1.9-7.5-0.9-15.5,2.1-22.6
				c0.9-2.2,1.8-4.8,2.5-7.7c2.1-9.5-2.4-19.1-10.8-24C521.6,285.4,509.1,280.7,493.2,283.1z"
            />
            <path
              class="st11"
              d="M492.8,282.8c0,0,23-15.4,50.1-12c28.8,3.6,48.5,31.8,41.5,60.1c-0.1,0.3-0.1,0.5-0.2,0.8
				c-1.2,4.5-2.8,8.8-4.7,13c-2.6,5.9-7.2,22.3,16.8,30.3c5.8,1.9,11.3,5,15.6,9.3c9.1,9.1,16.5,26-8.5,51.4c0,0,19.3-24.9,1-41.1
				c0,0-9-6.2-17.6-7.1c0,0-26.1-2.5-30.1-25.9c-1-6-0.1-12.1,2-17.7c1.5-4.2,3.9-9.7,7-15c2.5-4.2,4.1-8.9,4.5-13.8
				c0.8-9-1.4-21.8-17.2-29.3C553,285.8,527.4,271.6,492.8,282.8z"
            />
            <path
              class="st10"
              d="M493.2,282.9c0,0-21-23.7-65.1-18.7c0,0-24.7,0.8-29,32.6c-0.7,5-2.3,9.8-4.9,14.1
				c-5.1,8.2-15.6,17.7-37.7,14c0,0-30.7-1.9-24.4,32.6c0,0,21.1,37.7,6.3,56.5c0,0-14.3,8.4-16.4,15.5c0,0-4.3,9-2.2,16.4
				c0,0,2.7-18.5,23.3-25c0,0,23.6-11.5,13.5-37.7c0,0-17.9-24.9,3.5-38.2c0,0,8.1-3.9,23.7-3.9c0,0,16.3-2.9,20.7-20.9
				c1.7-6.9,4.8-13.5,9.6-18.8C425,289.5,448,275,493.2,282.9z"
            />
            <path
              class="st0"
              d="M402.6,362.9c0,0-7.8-18.8,6.3-25.4c0,0,6.3-1.7,12.3-0.9c0,0,26.6,2.1,21.7-26.6c0,0-0.1-0.3-0.3-0.7
				c-4.9-12.8,3.4-26.8,17-28.9c7.5-1.2,18.6-1.3,33.6,2.6c0,0-14.1-9.6-30-11.5c-13.3-1.5-26.4,5-32.6,16.9c-3,5.8-4.6,13-0.7,20.7
				c0,0,7.8,17.9-20.3,19C409.5,327.9,383.3,332.4,402.6,362.9z"
            />
            <path
              class="st10"
              d="M492.2,282.9c0,0-16.9-6.9-32.7-2c-12.6,3.8-20.5,16-19.9,29.2c0.2,3.6,0.7,7.6,2,12.1
				c1.3,4.6,1.9,9.4,1.4,14.1c-1.2,10.6-6.9,26.3-29.7,26.3c0,0,32,3.9,38.6-21.7c1.8-7.1,0.8-14.6-2-21.4c-0.9-2.1-1.7-4.6-2.3-7.3
				c-2-9,2.2-18.1,10.2-22.7C465.3,285.1,477.1,280.7,492.2,282.9z"
            />
            <path
              class="st11"
              d="M493.2,282.9c0,0-11-4.5-21.4-1.3c-8.2,2.5-13.4,10.5-13,19.1c0.1,2.3,0.5,5,1.3,7.9c0.9,3,1.3,6.1,0.9,9.2
				c-0.8,6.9-4.5,17.2-19.4,17.2c0,0,20.9,2.5,25.2-14.2c1.2-4.7,0.5-9.6-1.3-14c-0.6-1.3-1.1-3-1.5-4.8c-1.3-5.9,1.5-11.8,6.7-14.8
				C475.6,284.3,483.4,281.5,493.2,282.9z"
            />
            <path
              class="st5"
              d="M620,344.3c0,0-22-14.2-10.6-36.8c0,0,18.5-27.3-10.6-44.4c0,0-22.8-14.1-74.1,7.2c0,0-20.3,7.6-31.6,12.7
				c0,0,50.5-13,83.1-9.1c0,0,19.8,4.2,21.4,18.1c0.6,5.6,0.4,11.2-1,16.6C594.4,317.1,592.3,337.4,620,344.3z"
            />
            <path
              class="st10"
              d="M783,367.2c-3-7.5-8.5-12.4-13.9-11.4c-2.7,0.5-3.8,1.7-6.3,2.7c-1.9,0.7-3.7,1-5.4,1.3
				c-27.1,5-49-5.7-43.3-28.3c0.8-3.1,1.8-5.8,2.7-8.2c3.7-9.1,0.4-21.8-9.3-23.5c-7.4-1.4-17.2,1.6-17.2,1.6
				c-49.2,14.1-61.3-0.6-68.2-9.1c-6.4-7.9-14.1-23.7-23.9-26.4c-42.8-11.8-101.2,15.1-105,17c4.8-3.3,106.8-71.2,136.6-17.9l0,0
				c9.6,25.9,51.2,16.7,67.9,11.8c5.7-1.7,11.7-2.2,17.4-0.9c14.8,3.4,19.9,11.7,21.4,19.2c1.2,6-0.1,12.1-2.9,17.5
				c-2.2,4.4-3.3,8.7-3.7,12.2c-0.8,6,0.4,12.2,4.2,16.9c9.1,11.2,25.7,8.7,25.7,8.7c4.2-0.4,8.6-1.5,10.9-1.2
				C779.9,350.6,780.6,355.9,783,367.2z"
            />
          </g>
        </g>
      </g>
      <g id="inner-face">
        <g id="nose">
          <ellipse class="st14" cx="563.4" cy="534" rx="10.3" ry="7.3" />
        </g>
        <g id="eyes-closed" class="st15">
          <g id="eyes-closed-left" class="st16">
            <path
              class="st17"
              d="M466.5,480.6c-22.4,1.8-51.8-7.5-58.4-36.8c-17.4,0-24.3,14.4-24.3,14.4c5.3-2,10.8,0.7,14.6,3.5
				c2.7,2,5.1,4.3,7,7.1c13.6,19.9,41.5,29.1,65,23c0,0,24.7-4.9,49.4-24.8C519.7,467,492.3,478.6,466.5,480.6z"
            />
            <path
              class="st17"
              d="M428.6,487.2c0,0-5.9,1.3-6.9,7.7c0,0-1.3-6.6,4.6-9.4L428.6,487.2z"
            />
            <path
              class="st17"
              d="M421.3,482.9c0,0-6.7,0.5-8.2,7.2c0,0-0.9-7.9,6.3-9.1L421.3,482.9z"
            />
            <path
              class="st17"
              d="M415.6,476.6c0,0-4.2-0.1-6.4,4.1c0,0,2.7-3,7.8-2.3L415.6,476.6z"
            />
          </g>
          <g id="eyes-closed-right" class="st16">
            <path
              class="st17"
              d="M657.9,480.6c22.4,1.8,51.8-7.5,58.4-36.8c17.4,0,24.3,14.4,24.3,14.4c-5.3-2-10.8,0.7-14.6,3.5
				c-2.7,2-5.1,4.3-7,7.1c-13.6,19.9-41.5,29.1-65,23c0,0-24.7-4.9-49.4-24.8C604.7,467,632.1,478.6,657.9,480.6z"
            />
            <path
              class="st17"
              d="M695.8,487.2c0,0,5.9,1.3,6.9,7.7c0,0,1.3-6.6-4.6-9.4L695.8,487.2z"
            />
            <path
              class="st17"
              d="M703.2,482.9c0,0,6.7,0.5,8.2,7.2c0,0,0.9-7.9-6.3-9.1L703.2,482.9z"
            />
            <path
              class="st17"
              d="M708.8,476.6c0,0,4.2-0.1,6.4,4.1c0,0-2.7-3-7.8-2.3L708.8,476.6z"
            />
          </g>
        </g>
        <g id="eyes">
          <g id="eye-shadow">
            <path
              class="st18"
              d="M408,453.6c0,0,10.4,28.9,43.9,34.8c0,0,23.8,5.8,57.6-9.7c0,0-34.8,24.4-72.8,10.6
				C436.7,489.3,413.1,480.3,408,453.6z"
            />
            <path
              class="st18"
              d="M716.6,453.4c0,0-10.4,28.9-43.9,34.8c0,0-23.8,5.8-57.6-9.7c0,0,34.8,24.4,72.8,10.6
				C687.9,489.1,711.5,480.2,716.6,453.4z"
            />
          </g>
          <g id="right-eye">
            <path
              class="st17"
              d="M615.1,478.7c0,0,2.3-41,34.8-57.9c26.7-13.9,60.1,3.5,66.6,32.9c17.4,0,24.3-14.4,24.3-14.4
				c-5.3,2-10.8-0.7-14.6-3.5c-2.7-2-5.1-4.3-7-7.1c-13.6-19.9-36.7-29.3-60.1-23.1c-21.2,5.5-36.1,21.2-41.4,42.2
				C615.2,458,615.1,465.6,615.1,478.7z"
            />
            <path
              class="st17"
              d="M697,478.1c0,0,5.9,1.3,6.9,7.7c0,0,1.3-6.6-4.6-9.4L697,478.1z"
            />
            <path
              class="st17"
              d="M703.4,472.8c0,0,6.7,0.5,8.2,7.2c0,0,0.9-7.9-6.3-9.1L703.4,472.8z"
            />
            <path
              class="st17"
              d="M709,466.5c0,0,4.2-0.1,6.4,4.1c0,0-2.7-3-7.8-2.3L709,466.5z"
            />
            <g id="right-eyeball">
              <path
                class="st19"
                d="M615.1,478.7c0,0,2.1-40.9,34.8-57.9c26.8-13.9,59.9,2.2,66.5,31.7c0.1,0.4,0.2,0.7,0.2,1.1
					C716.6,453.6,694.7,513.2,615.1,478.7z"
              />
              <g class="right-eyeball">
                <defs>
                  <path
                    id="SVGID_1_"
                    d="M615.1,478.7c0,0,2.1-40.9,34.8-57.9c26.8-13.9,59.9,2.2,66.5,31.7c0.1,0.4,0.2,0.7,0.2,1.1
							C716.6,453.6,694.7,513.2,615.1,478.7z"
                  />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <g class="st20">
                  <circle class="st21" cx="659.9" cy="455.4" r="34" />
                  <path
                    class="st13"
                    d="M673,462.8c2.2-3.1,7.1-1.2,6.7,2.5c-0.5,5.6-3.2,10.7-7.7,13.9c-9.2,6.7-22.9,3.5-30.5-7.1
							c-2.1-3-3.6-6.2-4.4-9.4c-0.9-4,4.3-6.2,6.7-2.9c0,0,0.1,0.1,0.1,0.1c6.5,9,18,11.7,25.7,6.1
							C670.9,465.2,672.1,464.1,673,462.8z"
                  />
                  <path
                    class="st19"
                    d="M644.9,434.7c2.5,3.4,1.7,8.3-1.7,10.7c-3.4,2.5-8.3,1.7-10.7-1.7c-2.5-3.4-1.7-8.3,1.7-10.7
							C637.6,430.4,642.4,431.2,644.9,434.7z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="left-eye">
            <path
              class="st17"
              d="M509.5,478.7c0,0-2.3-41-34.8-57.9c-26.7-13.9-60.1,3.5-66.6,32.9c-17.4,0-24.3-14.4-24.3-14.4
				c5.3,2,10.8-0.7,14.6-3.5c2.7-2,5.1-4.3,7-7.1c13.6-19.9,36.7-29.3,60.1-23.1c21.2,5.5,36.1,21.2,41.4,42.2
				C509.4,458,509.5,465.6,509.5,478.7z"
            />
            <path
              class="st17"
              d="M427.6,478.1c0,0-5.9,1.3-6.9,7.7c0,0-1.3-6.6,4.6-9.4L427.6,478.1z"
            />
            <path
              class="st17"
              d="M421.3,472.8c0,0-6.7,0.5-8.2,7.2c0,0-0.9-7.9,6.3-9.1L421.3,472.8z"
            />
            <path
              class="st17"
              d="M415.6,466.5c0,0-4.2-0.1-6.4,4.1c0,0,2.7-3,7.8-2.3L415.6,466.5z"
            />
            <g id="left-eyeball">
              <path
                class="st19"
                d="M509.5,478.7c0,0-2.1-40.9-34.8-57.9c-26.8-13.9-59.9,2.2-66.5,31.7c-0.1,0.4-0.2,0.7-0.2,1.1
					C408,453.6,429.9,513.2,509.5,478.7z"
              />
              <g class="left-eyeball">
                <defs>
                  <path
                    id="SVGID_3_"
                    d="M509.5,478.7c0,0-2.1-40.9-34.8-57.9c-26.8-13.9-59.9,2.2-66.5,31.7c-0.1,0.4-0.2,0.7-0.2,1.1
							C408,453.6,429.9,513.2,509.5,478.7z"
                  />
                </defs>
                <clipPath id="SVGID_4_">
                  <use xlink:href="#SVGID_3_" style="overflow:visible;" />
                </clipPath>
                <g class="st22">
                  <circle class="st21" cx="464.8" cy="455.4" r="34" />
                  <path
                    class="st13"
                    d="M451.7,462.8c-2.2-3.1-7.1-1.2-6.7,2.5c0.5,5.6,3.2,10.7,7.7,13.9c9.2,6.7,22.9,3.5,30.5-7.1
							c2.1-3,3.6-6.2,4.4-9.4c0.9-4-4.3-6.2-6.7-2.9c0,0-0.1,0.1-0.1,0.1c-6.5,9-18,11.7-25.7,6.1
							C453.7,465.2,452.6,464.1,451.7,462.8z"
                  />
                  <path
                    class="st19"
                    d="M479.8,434.7c-2.5,3.4-1.7,8.3,1.7,10.7c3.4,2.5,8.3,1.7,10.7-1.7c2.5-3.4,1.7-8.3-1.7-10.7
							C487.1,430.4,482.3,431.2,479.8,434.7z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="lips">
          <g>
            <path
              class="st23"
              d="M540.4,578.5c0,0,9.8-13.3,22.1-4.7c0,0,12.4-8.1,21.9,4.7H540.4z"
            />
            <path class="st24" d="M540.4,579.3c0,0,23.1,16.8,44,0H540.4z" />
          </g>
        </g>
        <g id="eyebrows">
          <ellipse
            id="eyebrow-right"
            transform="matrix(0.992 -0.126 0.126 0.992 -41.7675 85.9009)"
            class="st1 eyebrow-right"
            cx="658.1"
            cy="373.1"
            rx="27"
            ry="6"
          />

          <ellipse
            id="eyebrow-left"
            transform="matrix(0.126 -0.992 0.992 0.126 37.6165 788.912)"
            class="st1 eyebrow-left"
            cx="466.5"
            cy="373.1"
            rx="6"
            ry="27"
          />
        </g>
      </g>
      <g id="glasses">
        <g>
          <circle class="st25" cx="397.6" cy="490.4" r="143" />
          <circle class="st25" cx="729.2" cy="490.4" r="143" />
          <line class="st25" x1="586.1" y1="490.4" x2="540.6" y2="490.4" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-dom";
import gsap from "gsap";

export default defineComponent({
  name: "Mini Koko",
  data: () => {
    return {
      isAnimActive: false,
    };
  },
  mounted: () => {
    const blink = gsap.timeline({
      repeat: -1,
      repeatDelay: 3.5,
      paused: true,
    });

    blink
      .to(
        "#right-eye, #left-eye",
        {
          duration: 0.05,
          opacity: 0,
        },
        0
      )
      .to(
        "#eyes-closed-right, #eyes-closed-left",
        {
          duration: 0.05,
          opacity: 1,
        },
        0
      )
      .to(
        "#right-eye, #left-eye",
        {
          duration: 0.05,
          opacity: 1,
        },
        0.15
      )
      .to(
        "#eyes-closed-right, #eyes-closed-left",
        {
          duration: 0.05,
          opacity: 0,
        },
        0.15
      );

    // "look at cursor" code
    let xPosition;
    let yPosition;

    let height;
    let width;

    function percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    }

    function updateScreenCoords(event) {
      xPosition = event.clientX;
      yPosition = event.clientY;
    }

    let storedXPosition = 0;
    let storedYPosition = 0;

    // gsap can use queryselector in the quick setter but this is better for performance as it touches the DOM less
    const dom = {
      head: document.querySelector("#head"),
      innerFace: document.querySelector("#inner-face"),
      cheeks: document.querySelector("#cheeks"),
      eye: document.querySelectorAll("#eyes"),
      bangs: document.querySelector(".bangs"),
      glasses: document.querySelector("#glasses"),
      shadow: document.querySelectorAll(".shadow"),
      bangsShadow: document.querySelectorAll("#bangs-shadow"),
      ear: document.querySelectorAll(".ears"),
      mouth: document.querySelectorAll(".mouth"),
      eyebrowLeft: document.querySelector(".eyebrow-left"),
      eyebrowRight: document.querySelector(".eyebrow-right"),
      leftEyeBall: document.querySelector(".left-eyeball"),
      rightEyeBall: document.querySelector(".right-eyeball"),

      bgTriangle: document.querySelector(".bg-triangle"),
    };

    function animateFace() {
      if (!xPosition) return;
      // important, only recalculating if the value changes
      if (storedXPosition === xPosition && storedYPosition === yPosition)
        return;

      // range from -50 to 50
      let x = percentage(xPosition, width) - 50;
      let y = percentage(yPosition, height) - 50;

      // range from -20 to 80
      let yHigh = percentage(yPosition, height) - 60;
      // range from -80 to 20
      let yLow = percentage(yPosition, height) - 120;

      gsap.to(dom.head, {
        yPercent: yLow / 30,
        xPercent: x / 30,
      });
      gsap.to(dom.innerFace, {
        yPercent: yHigh / 3,
        xPercent: x / 4,
      });
      gsap.to([dom.glasses, dom.cheeks], {
        yPercent: yHigh / 6,
        xPercent: x / 8,
      });
      gsap.to(dom.bangs, {
        yPercent: yHigh / 100,
        xPercent: x / 35,
      });
      gsap.to(dom.shadow, {
        yPercent: (yLow / 40) * -1,
        xPercent: (x / 40) * -1,
      });
      gsap.to(dom.bangsShadow, {
        yPercent: (yLow / 140) * -1,
        xPercent: (x / 60) * -1,
      });
      gsap.to(dom.ear, {
        yPercent: (y / 1.5) * -1,
        xPercent: (x / 50) * -1,
      });
      gsap.to([dom.eyebrowLeft, dom.eyebrowRight], {
        yPercent: y / 3,
      });
      gsap.to([dom.leftEyeBall, dom.rightEyeBall], {
        yPercent: y / 14,
        xPercent: x / 6,
      });

      storedXPosition = xPosition;
      storedYPosition = yPosition;
    }

    function addMouseEvent() {
      const safeToAnimate = window.matchMedia(
        "(prefers-reduced-motion: no-preference)"
      ).matches;

      if (safeToAnimate) {
        window.addEventListener("mousemove", updateScreenCoords);

        // gsap's RAF, falls back to set timeout
        gsap.ticker.add(animateFace);

        blink.play();
      }
    }

    // update if browser resizes
    function updateWindowSize() {
      height = window.innerHeight;
      width = window.innerWidth;

      const head = dom.head.getBoundingClientRect();
      const triSize = `0px ${(head.width + 50) / 2}px ${head.height + 50}px`;
      dom.bgTriangle.style.borderWidth = triSize;
    }
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    addMouseEvent();
  },
});
</script>

<style lang="scss" scoped>
@import "../styles/_defaultVars.scss";

.avatar {
  display: flex;
  place-items: center;
  place-content: center;
  position: relative;

  .bg-triangle {
    position: absolute;
    transform: rotate(335deg);
    bottom: 15%;
    right: 10%;

    // triangle
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10vw 10vh 10vw;
    border-color: transparent transparent $dark-blue transparent;
  }

  svg {
    z-index: 10;
    height: 100%;
    width: 100%;

    &.animate-koko {
      background-color: pink;
      border: solid 2px red;
    }

    // mini-koko colors
    .st0 {
      fill: #fd2701;
    }
    .st1 {
      fill: #e71303;
      stroke: #e71303;
      stroke-width: 0.4568;
      stroke-miterlimit: 10;
    }
    .st2 {
      fill: #ff5800;
    }
    .st3 {
      fill: #ff4905;
    }
    .st4 {
      fill: #e71303;
    }
    .st5 {
      fill: #ff6000;
    }
    .st6 {
      fill: #f9e4eb;
    }
    .st7 {
      fill: none;
      stroke: #6d555e;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }
    .st8 {
      opacity: 0.15;
    }
    .st9 {
      fill: #fcf0f4;
    }
    .st10 {
      fill: #ff8658;
    }
    .st11 {
      fill: #ff390e;
    }
    .st12 {
      opacity: 0.3;
    }
    .st13 {
      fill: #a47148;
    }
    .st14 {
      fill: #ffcccc;
    }
    .st16 {
      display: inline;
    }
    .st17 {
      fill: #0c0c0c;
    }
    .st18 {
      opacity: 0.3;
      fill: #a47148;
    }
    .st19 {
      fill: #ffffff;
    }
    .st20 {
      clip-path: url(#SVGID_2_);
    }
    .st21 {
      fill: #8b5e34;
      stroke: #603808;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }
    .st22 {
      clip-path: url(#SVGID_4_);
    }
    .st23 {
      fill: #ed6969;
    }
    .st24 {
      fill: #ef7b7b;
    }
    .st25 {
      fill: none;
      stroke: #6097c6;
      stroke-width: 3;
      stroke-miterlimit: 10;
    }
  }
}
</style>
